import { Card, CardContent } from '@mui/material';
import { HrsIntegrationProviders, PaylocityIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { GenerateHrsIntegrationKey, ViewHrsIntegrationKey } from 'api/generated/permissions';
import Button from 'components/Button';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import PaylocityActionButtons from 'pages/integrations/PaylocityActionButtons';
import PaylocityConfigurationModal from 'pages/integrations/PaylocityConfigurationModal';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { enumToString } from 'utilities/index';
import { string } from 'yup';

const earningCodeMaxLength = 50;
const earningCodeSchema = string()
    .max(earningCodeMaxLength)
    .label('Earning Code');

const PaylocityIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const { canGeneratePaylocityIntegrationKey, canViewPaylocityIntegrationKey } = useSelector(
        (state: AppStore) => ({
            canGeneratePaylocityIntegrationKey: hasSomePermissions(
                state,
                GenerateHrsIntegrationKey
            ),
            canViewPaylocityIntegrationKey: hasSomePermissions(state, ViewHrsIntegrationKey),
        })
    );
    const {
        closeModal: closeConfigurationModal,
        isVisible: isConfigurationModalVisible,
        openModal: openConfigurationModal,
    } = useModalState();

    const { hrsIntegrationProvider, paylocityIntegrationStatus, team } = useTeamProps();
    const hrsProviderName = enumToString(
        HrsIntegrationProviders,
        hrsIntegrationProvider ?? HrsIntegrationProviders.NotConfigured
    );
    const [earningCode1095C, setEarningCode1095C] = useState(team?.earningCode1095C ?? '');
    const OverrideActionButtons = useCallback(() => <PaylocityActionButtons team={team} />, [team]);

    return (
        <Card>
            <CardContent>
                <DashboardCardHeader
                    header="Paylocity"
                    OverrideActionButtons={OverrideActionButtons}
                />
                <hr />
                {isConfigurationModalVisible && (
                    <PaylocityConfigurationModal close={closeConfigurationModal} />
                )}
                {canGeneratePaylocityIntegrationKey &&
                    [
                        PaylocityIntegrationStatuses.NotConfigured,
                        PaylocityIntegrationStatuses.Disconnected,
                    ].contains(paylocityIntegrationStatus) && (
                        <Button
                            className="mr-3"
                            onClick={openConfigurationModal}
                            size="small"
                            variant="outlined"
                        >
                            Configure Paylocity Connection
                        </Button>
                    )}
                {canViewPaylocityIntegrationKey && (
                    <React.Fragment>
                        <ProfileAttribute label="Status">
                            {startCase(
                                PaylocityIntegrationStatuses[
                                    team?.paylocityIntegrationStatus ??
                                        PaylocityIntegrationStatuses.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                        {paylocityIntegrationStatus !==
                            PaylocityIntegrationStatuses.NotConfigured && (
                            <ProfileAttribute label="Key">
                                {team?.paylocityIntegrationKey}
                            </ProfileAttribute>
                        )}
                        {paylocityIntegrationStatus === PaylocityIntegrationStatuses.Connected && (
                            <React.Fragment>
                                <ProfileAttribute
                                    infoTooltip="The organization's Company ID in Paylocity"
                                    label="Organization ID"
                                >
                                    {team?.paylocityOrganizationId}
                                </ProfileAttribute>
                                <ProfileAttribute
                                    infoTooltip="The organization's OAuth Client ID"
                                    label="Client ID"
                                >
                                    {team?.paylocityClientId}
                                </ProfileAttribute>
                                <EditableTextAttribute
                                    infoTooltip={`The earning code in ${hrsProviderName} that will be used for 1095-C reporting. This corresponds with the used portion of a member's Reimbursement amount.`}
                                    label="1095-C Earning Code"
                                    name="earningCode1095C"
                                    onChange={onChange(setEarningCode1095C)}
                                    save={save}
                                    validationSchema={earningCodeSchema}
                                    value={earningCode1095C}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
};

export default hot(module)(PaylocityIntegrationSection);
