import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    SET_CUSTOM_EXPENSES_ACTION,
    setCustomExpenses,
} from 'actions/expenseSummary/setCustomExpenses';
import { CustomExpenseTypes, TeamBenefitTypes } from 'api/generated/enums';
import { IExpenseSummaryCustomExpense, IPayrollReportDto } from 'api/generated/models';
import { EditPayrollReports } from 'api/generated/permissions';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import InformationIconTooltip from 'components/InformationIconTooltip';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { MONTHS } from 'constants/date';
import useEditableListState, { ListItemChangeCallback } from 'hooks/useEditableListState';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import sumBy from 'lodash/sumBy';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import styled from 'styled-components';
import { enumToNameValueArray, hasContents, hasValue, stringToFloat, stringToInt } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { IFormErrors, onChange } from 'utilities/forms';
import { array, number, object, string } from 'yup';

const SHARED_RESPONSIBILITY_TOOLTIP =
    'This is an estimate of the employer shared responsibility payment owed for this month.';

const AmountText = styled.span`
    font-weight: initial;
`;

type IExpenseSummaryLineItem = {
    amount: number | undefined;
    label: string | undefined;
    subText?: ReactNode;
    tooltip?: string;
};

const ExpenseSummaryLineItem = ({ amount, label, subText, tooltip }: IExpenseSummaryLineItem) => (
    <Stack>
        <Typography fontWeight={500} variant="h5">
            <Stack alignItems="center" direction="row" justifyContent="space-between">
                <span>
                    {label} {hasValue(tooltip) && <InformationIconTooltip title={tooltip} />}
                </span>
                <AmountText>{formatCurrency(amount, { preserveDecimal: true })}</AmountText>
            </Stack>
        </Typography>
        {subText}
    </Stack>
);

const schema = object({
    customExpenses: array(
        object({
            amount: number()
                .transform(stringToFloat)
                .required()
                .label('Expense'),
            customExpenseType: number()
                .transform(stringToInt)
                .required()
                .label('Expense Type'),
            name: string()
                .trim()
                .required()
                .label('Name'),
        })
    ),
});

type ICustomExpenseLineItem = {
    amount: number | undefined;
    customExpenseTypeId: CustomExpenseTypes | undefined;
    errors: IFormErrors<typeof schema> | null | undefined;
    index: number;
    itemKey: number;
    name: string | undefined;
    removeExpense: (key: number) => () => void;
    setValue: ListItemChangeCallback<IExpenseSummaryCustomExpense>;
};

const RH_CUSTOM_EXPENSE_TYPE_ITEMS = enumToNameValueArray(CustomExpenseTypes, {
    formatName: startCase,
});

const CustomExpenseLineItem = ({
    amount,
    customExpenseTypeId,
    errors,
    index,
    itemKey,
    name,
    removeExpense,
    setValue,
}: ICustomExpenseLineItem) => (
    <Stack alignItems="center" direction="row" gap={2}>
        <TextField
            errors={errors?.[(`customExpenses[${index}].name` as unknown) as 'customExpenses']}
            onChange={onChange(setValue(itemKey, 'name'))}
            placeholder="Name"
            value={name}
        />
        <CurrencyTextField
            allowNegative
            errors={errors?.[(`customExpenses[${index}].amount` as unknown) as 'customExpenses']}
            onChange={onChange(setValue(itemKey, 'amount'))}
            placeholder="Expense"
            value={amount}
        />
        <Select
            defaultText="Choose expense type"
            defaultValue=""
            errors={
                errors?.[
                    (`customExpenses[${index}].customExpenseType` as unknown) as 'customExpenses'
                ]
            }
            items={RH_CUSTOM_EXPENSE_TYPE_ITEMS}
            label="Expense Type"
            onChange={onChange(setValue(itemKey, 'customExpenseType'))}
            optionText="name"
            optionValue="value"
            value={customExpenseTypeId}
        />
        <IconButton aria-label="remove" onClick={removeExpense(itemKey)} size="small">
            <CloseIcon fontSize="small" />
        </IconButton>
    </Stack>
);

const createNewCustomExpense: () => Partial<IExpenseSummaryCustomExpense> = () => ({
    name: '',
});

type IExpenseSummaryModal = {
    close: () => void;
    payrollReport: IPayrollReportDto | undefined;
};

const ExpenseSummaryModal = ({ close, payrollReport }: IExpenseSummaryModal) => {
    const dispatch = useThunkDispatch();
    const { canEditPayrollReport, isRh, isSaving } = useSelector((state: AppStore) => ({
        canEditPayrollReport: hasSomePermissions(state, EditPayrollReports),
        isRh: isRhSelector(state),
        isSaving: hasApiActivity(state, SET_CUSTOM_EXPENSES_ACTION),
    }));
    const isReadonly = payrollReport?.isLocked || !canEditPayrollReport;
    const currentMonthIndex = (payrollReport?.month ?? NaN) - 1;
    const currentMonth = MONTHS[currentMonthIndex]?.name;
    const expenseSummary = payrollReport?.expenseSummary;
    const {
        addNew: addNewCustomExpense,
        items: customExpenses,
        onItemFieldChange,
        removeItem: removeCustomExpense,
    } = useEditableListState<Partial<IExpenseSummaryCustomExpense>>(
        expenseSummary?.customExpenses ?? [],
        createNewCustomExpense
    );

    const { errors, validate } = useForm(schema);
    const onSubmit = async () => {
        const { isValid } = await validate({ customExpenses } as {
            customExpenses: Required<IExpenseSummaryCustomExpense>[];
        });
        if (isValid) {
            await dispatch(setCustomExpenses(expenseSummary?.id, customExpenses));
            close();
        }
    };

    const customExpenseLineItems = customExpenses.map((x, i) => {
        if (isReadonly) {
            return <ExpenseSummaryLineItem amount={x.amount} label={x.name} />;
        }

        return (
            <CustomExpenseLineItem
                amount={x.amount}
                customExpenseTypeId={x.customExpenseType}
                errors={errors}
                index={i}
                itemKey={x.key}
                key={x.key}
                name={x.name}
                removeExpense={removeCustomExpense}
                setValue={onItemFieldChange}
            />
        );
    });

    const customExpenseTotal = sumBy(customExpenses, (x) => Number(x.amount ?? 0));
    const total = (expenseSummary?.total ?? 0) + customExpenseTotal;
    const showIchraReimbursementTotal = payrollReport?.payrollReportUserSnapshots?.some((prus) =>
        hasValue(prus.ichraReimbursement)
    );

    return (
        <Dialog fullWidth maxWidth="sm" onClose={close} open>
            <DialogTitle>
                Monthly Expense Summary for {currentMonth} {payrollReport?.year}
            </DialogTitle>
            <DialogContent dividers>
                <Form id="edit-custom-expenses-form" isLoading={isSaving} onSubmit={onSubmit}>
                    <Stack alignItems="stretch" gap={2} pt={1}>
                        <ExpenseSummaryLineItem
                            amount={payrollReport?.wageUpTotal}
                            label="Wage+ Total"
                            tooltip="The total of all Wage+ amounts given to employees this month, including any one-time adjustments."
                        />
                        {showIchraReimbursementTotal && (
                            <ExpenseSummaryLineItem
                                amount={payrollReport?.ichraReimbursementTotal}
                                label="ICHRA Reimbursement Total"
                                tooltip="The total of all ICHRA Reimbursements for employees this month, including any one-time adjustments."
                            />
                        )}
                        <ExpenseSummaryLineItem
                            amount={expenseSummary?.ficaEmployerExpense}
                            label="FICA Employer Expense"
                            tooltip="This is an estimate of the additional employer FICA tax responsibility owed this month due to the total Wage+ amounts provided to employees."
                        />
                        {expenseSummary?.teamIsAle && (
                            <ExpenseSummaryLineItem
                                amount={expenseSummary?.sharedResponsibility}
                                label="Shared Responsibility"
                                tooltip={
                                    isRh
                                        ? expenseSummary?.sharedResponsibilityEquation
                                        : SHARED_RESPONSIBILITY_TOOLTIP
                                }
                            />
                        )}
                        {hasValue(expenseSummary?.hraUtilization) && (
                            <ExpenseSummaryLineItem
                                amount={expenseSummary?.hraUtilization}
                                label="Monthly HRA Utilization Estimate"
                                tooltip="This is an estimate of how much should be set aside for employee HRA utilization this month."
                            />
                        )}
                        {hasValue(expenseSummary?.reimbursementProgramUtilization) && (
                            <ExpenseSummaryLineItem
                                amount={expenseSummary?.reimbursementProgramUtilization}
                                label="Monthly Reimbursement Program Utilization Estimate"
                                tooltip="This is an estimate of how much should be set aside for employee Reimbursement Program utilization this month."
                            />
                        )}

                        <ExpenseSummaryLineItem
                            amount={expenseSummary?.monthlySubscriptionFee}
                            label="Monthly RH Subscription Fee"
                            tooltip="This is the monthly subscription fee charged by Remodel Health."
                        />
                        {hasValue(expenseSummary?.expenseSummaryTeamBenefitsTotal) &&
                            hasContents(expenseSummary?.expenseSummaryTeamBenefits) && (
                                <ExpenseSummaryLineItem
                                    amount={expenseSummary?.expenseSummaryTeamBenefitsTotal}
                                    label="Team Benefits Total"
                                    subText={
                                        <ul>
                                            {expenseSummary?.expenseSummaryTeamBenefits?.map(
                                                (x) => (
                                                    <li key={x.teamBenefitTypeId}>
                                                        {startCase(
                                                            TeamBenefitTypes[x.teamBenefitTypeId]
                                                        )}{' '}
                                                        Cost:{' '}
                                                        {formatCurrency(x.total, {
                                                            preserveDecimal: true,
                                                        })}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    }
                                    tooltip="The total cost of all Team Benefits for the organization."
                                />
                            )}
                        {customExpenseLineItems}
                        {!isReadonly && (
                            <Button onClick={addNewCustomExpense} variant="outlined">
                                Add Expense
                            </Button>
                        )}
                        <Typography fontWeight={500} textAlign="center" variant="h5">
                            Total:{' '}
                            <AmountText>
                                {formatCurrency(total, { preserveDecimal: true })}
                            </AmountText>
                        </Typography>
                    </Stack>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Close
                </Button>
                {!isReadonly && (
                    <LoadingButton
                        form="edit-custom-expenses-form"
                        loading={isSaving}
                        type="submit"
                        variant="text"
                    >
                        Save
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ExpenseSummaryModal);
