import { DISCONNECT_PAYLOCITY, disconnectPaylocity } from 'actions/paylocity/disconnectPaylocity';
import { PaylocityIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { GenerateHrsIntegrationKey } from 'api/generated/permissions';
import ActionButtons from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';

const PaylocityActionButtons = ({ team }: { team: ITeam | undefined }) => {
    const { canDisconnectPaylocity, showActivity } = useSelector((state: AppStore) => ({
        canDisconnectPaylocity: hasSomePermissions(state, GenerateHrsIntegrationKey),
        showActivity: hasApiActivity(state, DISCONNECT_PAYLOCITY),
    }));

    const dispatch = useThunkDispatch();
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);

    const onDisconnectYesClick = useCallback(async () => {
        await dispatch(disconnectPaylocity(team?.teamId ?? ''));
        setShowDisconnectModal(false);
    }, [dispatch, team?.teamId]);

    const actions = [
        {
            isVisible:
                team?.paylocityIntegrationStatus === PaylocityIntegrationStatuses.Connected &&
                canDisconnectPaylocity,
            onClick: () => setShowDisconnectModal(true),
            text: 'Disconnect Paylocity',
        },
    ];

    return (
        <React.Fragment>
            <ActionButtons isAlwaysDropdown items={actions} />
            {showDisconnectModal && (
                <ConfirmationModal
                    body="Are you sure you want to disconnect Paylocity?"
                    onNoClick={() => setShowDisconnectModal(false)}
                    onYesClick={onDisconnectYesClick}
                    showActivity={showActivity}
                    title="Disconnect Paylocity"
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(PaylocityActionButtons);
