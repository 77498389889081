import { Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { IAncillaryBenefitDto } from 'api/generated/models';
import useUserProps from 'hooks/useUserProps';
import { getStandardAncillaryCost } from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionUtilities';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';
import { getMemberNamesString } from 'utilities/household';
import { hasValue } from 'utilities/index';
import startCase from 'lodash/startCase';
import { TeamBenefitTypes } from 'api/generated/enums';
import { getLifeInsuranceAncillaryCost } from 'pages/dashboard/ancillaryBenefitSelection/AncillaryDisplayItem';

const AcceptedAncillary = ({ ancillary }: { ancillary: IAncillaryBenefitDto }) => {
    const { user } = useUserProps();
    const { householdMembers } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
    }));
    const displayParticipantFirstName = (entityIds: string[]) => {
        const ancillaryHhms = householdMembers.filter((hhm) =>
            entityIds.contains(hhm.householdMemberId)
        );
        return getMemberNamesString(true, user, ancillaryHhms);
    };

    const displayAncillaryCost = (entityIds: string[], costData: Record<string, number>) => {
        const coveredMembers = householdMembers.filter((hhm) =>
            entityIds.contains(hhm.householdMemberId)
        );
        const costDataValue = ancillary.teamBenefitType === startCase(TeamBenefitTypes[TeamBenefitTypes.LifeInsurance])
            ? getLifeInsuranceAncillaryCost(ancillary.userId, costData, coveredMembers)
            : getStandardAncillaryCost(costData, coveredMembers);

        return formatCurrency(costDataValue, {
            preserveDecimal: true,
        });
    };
    return (
        <Grid item key={ancillary.teamBenefitId} lg={6} xs={12}>
            <Card variant="outlined">
                <CardContent>
                    <Grid container direction="column" paddingTop={0}>
                        <Grid container direction="row" item>
                            <Grid item justifyContent="start" marginTop={0}>
                                <Typography margin={0} variant="h6">
                                    {ancillary.teamBenefitType}
                                </Typography>
                                <Typography>
                                    {ancillary.carrierName}
                                    {hasValue(ancillary.planName) && ` - ${ancillary.planName}`}
                                </Typography>
                            </Grid>
                            <Grid alignSelf="start" item marginLeft="auto">
                                <Chip color="secondary" label="Selected" size="small" />
                            </Grid>
                        </Grid>
                        <Typography variant="h6">
                            This plan is for:{' '}
                            {displayParticipantFirstName(
                                ancillary.initiallyCoveredHouseholdMemberIds as string[]
                            )}
                        </Typography>
                        {hasValue(ancillary.costData) && (
                            <React.Fragment>
                                <Typography marginBottom={0} variant="h5">
                                    Your Cost:
                                </Typography>
                                <Typography color="secondary" marginTop={0} variant="h5">
                                    {`${displayAncillaryCost(
                                        ancillary.initiallyCoveredHouseholdMemberIds as string[],
                                        ancillary.costData
                                    )} / mo`}
                                </Typography>
                            </React.Fragment>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default hot(module)(AcceptedAncillary);
