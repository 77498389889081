import Stack from '@mui/material/Stack';
import {
    CONFIGURE_PAYLOCITY_CREDENTIALS,
    configurePaylocityCredentials,
} from 'actions/paylocity/configurePaylocityCredentials';
import ConfirmationModal from 'components/ConfirmationModal';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const MAX_ID_LENGTH = 50;
const MAX_SECRET_LENGTH = 120;
const schema = object({
    clientId: string()
        .trim()
        .required()
        .max(MAX_ID_LENGTH)
        .label('Client ID'),
    clientSecret: string()
        .trim()
        .required()
        .max(MAX_SECRET_LENGTH)
        .label('Client Secret'),
    paylocityOrganizationId: string()
        .trim()
        .required()
        .max(MAX_ID_LENGTH)
        .label('Paylocity Organization ID'),
});

const PaylocityConfigurationModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, CONFIGURE_PAYLOCITY_CREDENTIALS),
    }));

    const [paylocityOrganizationId, setPaylocityOrganizationId] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const { errors, validate } = useForm(schema);

    const onYesClickSetAllowShopping = useCallback(async () => {
        const { isValid } = await validate({ clientId, clientSecret, paylocityOrganizationId });
        if (isValid) {
            await dispatch(
                configurePaylocityCredentials(teamId, {
                    clientId,
                    clientSecret,
                    paylocityOrganizationId,
                })
            );
            close();
        }
    }, [clientId, clientSecret, close, dispatch, paylocityOrganizationId, teamId, validate]);
    const body = useMemo(
        () => (
            <Stack gap={2}>
                <TextField
                    autoFocus
                    errors={errors?.paylocityOrganizationId}
                    label="Paylocity Organization ID"
                    name="paylocityOrganizationId"
                    onChange={onChange(setPaylocityOrganizationId)}
                    placeholder="Enter Organization ID"
                    value={paylocityOrganizationId}
                />
                <TextField
                    errors={errors?.clientId}
                    label="Client ID"
                    name="clientId"
                    onChange={onChange(setClientId)}
                    placeholder="Enter Client ID"
                    value={clientId}
                />
                <TextField
                    errors={errors?.clientSecret}
                    label="Client Secret"
                    name="clientSecret"
                    onChange={onChange(setClientSecret)}
                    placeholder="Enter Client Secret"
                    type="password"
                    value={clientSecret}
                />
            </Stack>
        ),
        [
            clientId,
            clientSecret,
            errors?.clientId,
            errors?.clientSecret,
            errors?.paylocityOrganizationId,
            paylocityOrganizationId,
        ]
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetAllowShopping}
            showActivity={showActivity}
            title="Set Allow Shopping For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(PaylocityConfigurationModal);
